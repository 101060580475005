body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background: linear-gradient(109deg, #101011 4.29%, #101011 99.55%);
  color: #ffffff;
  overflow-x: hidden;
}
button {
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize !important;
}
a {
  color: #5d87ff;
  text-decoration: none;
}
a:hover {
  color: #5d87ff;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #5d87ff;
}
img.brand_logo {
  width: 145px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #101011 !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* Dashboard Css */
.apppage_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.app_page {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.app_header {
  z-index: 100;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #101011;
  border-bottom: 1px solid #2f2f2f;
  transition: none;
  display: flex;
  align-items: stretch;
}
.app_header .offcanvas-body,
.app_header .offcanvas-body .navbar-nav {
  align-items: center;
  gap: 16px;
}
.notification_link {
  position: relative;
}
.notification_link span {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid #1d1e25;
  background: #ff5555;
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 5px;
}
.navbar_head {
  color: #5d87ff;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 32px;
}
.search_input_div {
  height: 52px;
}
.search_input_div .input-group-text {
  border-radius: 10px;
  background: rgba(26, 30, 39, 0.5);
  border: 1px solid rgba(26, 30, 39, 0.5);
  border-right: none;
}
.search_input_div .form-control {
  border-radius: 10px;
  background: rgba(26, 30, 39, 0.5);
  border: 1px solid rgba(26, 30, 39, 0.5);
  box-shadow: none !important;
  color: #fff;
  font-size: 18px;
  border-left: none;
}
.search_input_div .form-control::placeholder {
  color: rgba(60, 60, 67, 0.6);
}
button.navbar-toggler {
  box-shadow: none !important;
}
.offcanvas-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}
.app_sidebar {
  background: #101011;
  border-right: 1px solid #2f2f2f;
  width: 65px;
  position: fixed;
  z-index: 105;
  top: 65px;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
  height: 100%;
  overflow-x: hidden;
}
.app_sidebar .sidebar_menu li a span {
  display: none;
}
.app_sidebar.expanded .sidebar_menu li a span {
  display: block;
}
.app_sidebar.expanded {
  width: 240px;
}
.page_wrapper {
  margin-left: 65px;
  margin-top: 65px;
  transition: margin-left 0.3s ease, margin-right 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
}
.page_content {
  transition: margin 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
  height: calc(100vh - 65px);
  position: relative;
  z-index: 1;
}
.page_content_layout {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  container-type: size;
  container-name: layout;
}
.app_sidebar_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 180px);
}
.app_sidebar_logo {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.sidebar_menu {
  padding: 4px;
}
ul.sidebar_menu li + li {
  margin-top: 10px;
}
ul.sidebar_menu li a.nav-link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.8px;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px !important;
  position: relative;
  background: transparent;
  box-shadow: none;
  transition: 0.2s all;
  height: 48px;
}
ul.sidebar_menu li a.nav-link.active,
ul.sidebar_menu li a.nav-link:hover {
  background: #353535;
}
ul.sidebar_menu li svg {
  width: 24px;
  height: 24px;
  fill: #fff;
}
ul.sidebar_menu li a span {
  margin-left: 15px;
}
ul.sidebar_menu li a.nav-link.btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url("../src/assets/images/menu_caret_icon.svg");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  position: absolute;
  right: 10px;
  filter: invert(1);
}
ul.sidebar_menu li a.nav-link.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}
.sidebar_submenu {
  padding-left: 54px;
  margin-top: 12px;
}
.sidebar_submenu ul li a {
  position: relative;
  font-size: 14px;
  color: #fff;
  transition: 0.2s all;
}
.sidebar_submenu ul li a:hover {
  color: #f3ba2f;
}
.sidebar_submenu ul li a::before {
  content: "";
  background: #fff;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  top: 7px;
  left: -15px;
}
.page_title h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.usericon_dropdown span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  margin-left: 20px;
}
.topnavbar_right .dropdown-toggle::after {
  content: "";
  background: url("../src/assets/images/caret_icon.svg") no-repeat;
  border: 0;
  width: 15px;
  height: 10px;
  vertical-align: 0;
  margin-left: 15px;
}
.usericon_dropdown .dropdown-menu-dark {
  background: #1b232d;
  right: 0;
  left: unset;
  /* display: block;
  opacity: 0; */
  /* top: 100% !important;
  transform: translateY(20px) !important;
  transition: all 0.5s; */
  min-width: 200px;
}
/* .usericon_dropdown .dropdown-menu-dark.show {
  transform: translateY(0) !important;
} */
.usericon_dropdown .dropdown-menu-dark .dropdown-item.active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:hover {
  background-color: #5d87ff;
}
h2.dash_inner_title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
}
h2.dash_inner_title_small {
  font-size: 14px;
  line-height: normal;
}
.red_text {
  color: #e34a4a !important;
}
.green_text {
  color: #5be38a !important;
}
.blue_text {
  color: #87d4f6 !important;
}
.dashbox {
  border-radius: 10px;
  border: 1px solid #2a3340;
  background: #101011;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin-bottom: 24px;
}
.dashbox_small_pad {
  padding: 20px;
}
.link_box_panel {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 24px;
}
.link_box {
  border-radius: 10px;
  border: 1px solid #1e232e;
  background: #0e1116;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 20px;
  color: #fff;
  height: 100%;
  cursor: pointer;
}
.link_box h2 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}
.dashbox_fullheight {
  height: 100%;
}
.dash_inner_subtxt {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}
.rdt_TableCol,
.rdt_TableCell {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
}
.rdt_TableCell {
  color: #a5b3c8 !important;
  font-weight: 400;
}
.sale_order_bg {
  background: rgba(26, 160, 83, 0.36);
  display: inline-block;
  padding: 3px 5px;
}
.align_right {
  text-align: right;
  width: 100%;
}
.dashtitle_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.dashtitle_flex a {
  font-size: 14px;
}
.fa-rotate-45 {
  transform: rotate(45deg);
}
.total_fund_value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.total_fund_value h5 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  margin: 0 0 5px;
}
.total_fund_value h3 {
  color: #87d4f6;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}
.total_fund_value i {
  color: #3a3f4a;
}
.traffic_chart_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.asset_chart_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.asset_chart_info h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}
.asset_chart_info ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.asset_chart_info ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
}
.asset_chart_info ul li::before {
  content: "";
  background: #83fae3;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 100px;
}
.asset_chart_info ul li:nth-child(2):before {
  background: #fee083;
}
.asset_chart_info ul li:nth-child(3):before {
  background: #fe70b8;
}
.asset_chart_info ul li:nth-child(4):before {
  background: #7ef37d;
}
.asset_chart_info ul li p {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0;
}
.asset_chart_info ul li p span {
  font-weight: 700;
}
.asset_chart_info ul li label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}
button.border_btn {
  border-radius: 5px;
  background: #10101180;
  border: 2px solid #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px 20px;
  height: 40px;
  width: 100%;
}
button.border_btn a {
  color: #fff;
}
button.border_btn:hover {
  border: 2px solid #ff493c;
  background: #ff493c;
}
.btn-group_custom {
  background: #14171e;
  border-radius: 8px;
}
.btn-group_custom .grp_btn {
  background: #14171e;
  border: 1px solid #14171e;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 22px;
  border-radius: 8px;
  padding: 2px 10px;
}
.btn-group_custom .grp_btn.active {
  background: #5d87ff;
  color: #0e1116;
}
.hrs_chart_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
}
.view_all_link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}
.recent_activity_panel {
  margin-top: 24px;
}
.recent_activity_div {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
  background: #13181f;
  padding: 16px;
  margin-bottom: 18px;
  margin-right: 15px;
}
.activity_dot {
  background: #87d4f6;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-top: 4px;
}
.activity_content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-self: stretch;
  width: 100%;
}
.activity_content h2 {
  color: #87d4f6;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.464px;
  margin-bottom: 0;
}
.activity_content h5 {
  color: #e3eaef;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
}
.activity_content p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: right;
  margin-bottom: 0;
}
.trans_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trans_success .activity_dot {
  background: #5be38a;
}
.trans_success .activity_content h2 {
  color: #5be38a;
}
.trans_failed .activity_dot {
  background: #e34a4a;
}
.trans_failed .activity_content h2 {
  color: #e34a4a;
}
.custom_datepicker .react-datepicker-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}
.custom_datepicker .react-datepicker__input-container input {
  border: 1px solid #2f2f2f;
  border-radius: 5px;
  background: #101011;
  padding: 13px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  color: #fff;
  outline: none !important;
  width: 100%;
}
/* .custom_datepicker .react-datepicker-wrapper::before {
  content: "";
  background: url("../src/assets/images/calendar_icon.png") no-repeat right
    center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: -1;
} */
.react-datepicker {
  background-color: #101011 !important;
  color: #fff !important;
  border: 1px solid #2f2f2f !important;
}
.react-datepicker__header {
  background-color: #101011 !important;
  border-bottom: 1px solid #2f2f2f !important;
  color: #fff !important;
}
.react-datepicker__time-container .react-datepicker__time {
  background-color: #101011 !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
}
.react-datepicker__time-container {
  border-left: 1px solid #2f2f2f !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #2f2f2f !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: #2f2f2f !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -30px !important;
}
.react-datepicker__navigation {
  top: 8px !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #18202a !important;
  color: #fff !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #18202a !important;
  color: #ffffff !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #18202a !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #18202a !important;
  color: #fff !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #18202a !important;
  color: #fff !important;
}
.topnavbar_right {
  display: flex;
  align-items: center;
  gap: 30px;
}
.topnavbar_right i.fa-bars,
.app_sidebar i.close_icon {
  display: none;
}
.page_content .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.admin_profile img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.top_filter_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.top_filter {
  display: flex;
  align-items: center;
  gap: 15px;
}
.top_filter .MuiFormControl-root {
  width: 300px;
}
.MuiCheckbox-root {
  color: #f3ba2f !important;
}
.auth_header ul.navbar-nav {
  gap: 16px;
}
.auth_header ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
.auth_header ul li a:hover {
  color: #5d87ff;
}
/* .iWyFZZ div:first-child {
  white-space: pre-line !important;
} */

/* Login */
.auth_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url("../src/assets/images/auth_bg.webp") no-repeat center;
  background-size: cover;
  margin-top: -77px;
  z-index: 1;
}
.auth_wrapper::before {
  content: "";
  background: rgb(0 0 0 / 30%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.auth_main {
  border-radius: 10px;
  background: linear-gradient(
    100deg,
    rgb(14 17 22 / 98%) 9.14%,
    rgb(17 20 26 / 94%) 75.07%
  );
  width: 100%;
  height: 100%;
  padding: 50px;
  margin: 100px 0;
}
nav.navbar.navbar-expand-xl.navbar-dark.auth_header {
  z-index: 100;
  background: rgb(27 35 45 / 80%);
}
.auth_left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}
.auth_left h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.auth_left h2 span {
  font-weight: 700;
}
.auth_left label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.register_here {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 24px;
}
.register_here a {
  color: #5d87ff;
  text-decoration-line: underline;
}
.auth_form input.form-control {
  border-radius: 5px;
  border: 1px solid #2f2f2f !important;
  background: #101011 !important;
  backdrop-filter: blur(2.5px);
  padding: 18px 20px;
  color: #fff;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}
.auth_form input.form-control::placeholder {
  color: rgba(140, 154, 173, 0.7);
}
.auth_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auth_footer label {
  margin-bottom: 0;
}
.auth_footer a {
  color: #5d87ff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.auth_footer .form-check-input {
  border-radius: 2px;
  border: 1px solid #2f2f2f;
  background: rgba(0, 184, 129, 0.05);
  box-shadow: none;
}
.auth_form .form-group {
  margin-bottom: 20px;
}
.auth_main_forgot {
  background: #000;
  padding: 70px;
  justify-content: center;
}
.auth_main_forgot .auth_left {
  width: 50%;
}
.auth_main_forgot .auth_left img {
  width: 150px;
  margin-bottom: 40px;
}
.auth_main_forgot .auth_left h2 {
  font-size: 32px;
}
.auth_left button {
  border-radius: 5px;
  padding: 12px 30px;
  color: #11151a;
  font-family: "Outfit";
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  text-transform: capitalize;
}
.auth_left button a {
  color: #11151a !important;
}
.google_auth_inp_group {
  border-radius: 4px;
  background: #161616 !important;
  border: 1px solid #161616 !important;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}
.google_auth_inp_group input.form-control {
  width: 10%;
  border-bottom: 2px solid #fff !important;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.otp_wrap {
  display: flex;
  gap: 10px;
}
.otp_wrap input {
  text-align: center;
}
.dashbox_fullheight_row [class*="col-"] {
  margin-bottom: 24px;
}
.wallet_panel {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 24px;
}
.wallet_asset_div {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #1e232e;
  background: #0e1116;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}
.asset_btn_grp {
  display: flex;
  gap: 8px;
}
.asset_btn_grp button.border_btn {
  white-space: nowrap;
  padding: 10px 12px;
}
.wallet_asset_div h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.wallet_asset_div h5 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.primary_modal .modal-content {
  background: transparent;
  border: none;
}
.primary_modal .modal-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}
.primary_modal .modal-header {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #2f2f2f;
  background: #101011;
}
.primary_modal .modal-body {
  background: #101011;
  border-radius: 0 0 10px 10px;
}
.connect_wallet_button {
  background: #141417;
  color: #fff;
  width: 100%;
  border: 1px solid transparent;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 10px;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border: 1px solid #FCB879;
}
button.primary_btn {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}
.modal-backdrop {
  background: rgba(14, 17, 22, 0.8);
  backdrop-filter: blur(4.5px);
  opacity: 0.95 !important;
}
.primary_input {
  border-radius: 5px;
  border: 1px solid #2f2f2f !important;
  background: #101011 !important;
  padding: 12px 15px;
  box-shadow: none !important;
  color: #7c8fac !important;
  font-size: 16px;
  font-weight: 400;
  outline: none;
}
.primary_select {
  border-radius: 5px;
  border: 1px solid #2f2f2f !important;
  background: #101011 url("./assets/images/select_dropdown.png") no-repeat right
    0.75rem center;
  background-size: 12px 7px !important;
  color: #7c8fac;
  padding: 12px 15px;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
}
.primary_select::placeholder,
.primary_input::placeholder {
  color: rgba(140, 154, 173, 0.7);
}
.selected_bankdetails {
  text-align: center;
  margin-top: 30px;
}
.selected_bankdetails h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.selected_bankdetails h5 span {
  display: block;
}
.primary_modal .modal-footer button.border_btn {
  border: 1px solid #5d87ff;
  color: #5d87ff;
  background: transparent;
}
.quote_no {
  text-align: center;
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}
.quote_no span {
  color: #fff6ea;
  font-weight: 400;
}
.sendquote_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.sendquote_div_left h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
}
.sendquote_div_left h5 {
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}
.sendquote_div_left label {
  display: block;
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 10px 0;
  border-bottom: 1px solid #15181f;
}
.sendquote_div_left label span {
  color: #fff6ea;
  font-weight: 400;
}
.sendquote_div_left,
.sendquote_div_right {
  width: 50%;
}
.sendquote_div_right {
  text-align: center;
}
.qr_address {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px 0;
}
.qr_address p {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.sendquote_div_right h3 {
  color: rgba(255, 246, 234, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}
.sendquote_div_right h3 span {
  color: #fff6ea;
  font-weight: 400;
}
.table_preview img {
  max-width: 45px;
  object-fit: scale-down;
  height: 45px;
}
.table_btn_group {
  display: flex;
  align-items: center;
  gap: 8px;
}
.table_btn_group button {
  background: #405678;
  border: 1px solid #405678;
  border-radius: 3px;
  color: #09090a;
}
.custom_tooltip {
  width: 200px !important;
  opacity: 1 !important;
  background: #000 !important;
}
.status_circle {
  font-size: 11px;
}
.status_active {
  color: #60cd71;
}
.status_inactive {
  color: #d66068;
}
.MuiFormControl-root input {
  border-color: #2f2f2f !important;
  color: #7c8fac;
  background-color: #101011;
}
.MuiFormControl-root label {
  font-family: "Outfit" !important;
}
.MuiPaper-root {
  background: #1b2430 !important;
  color: #7c8fac !important;
}
.flex_btn_grp {
  display: flex;
  gap: 10px;
  align-items: center;
}
.css-ea7wse-control {
  min-height: 50px !important;
}
.css-z58v79-control {
  min-height: 50px !important;
}
.css-2mbwl4-multiValue {
  background-color: #10161d !important;
}
.css-j6087a-MultiValueGeneric,
.css-tj5bde-Svg {
  color: #7c8fac !important;
}
.css-171fg0w-MultiValueRemove:hover {
  background-color: #080a0d !important;
}
.css-1jqq78o-placeholder {
  color: #7c8fac !important;
}
.css-1a8zqyn-indicatorSeparator {
  display: none;
}
span.required {
  color: #ee1212;
}
.custom_tabbtn_grp {
  display: flex;
  gap: 10px;
}
.custom_tabbtn_grp button {
  color: #bec1c4;
  padding: 0 16px;
  border-radius: 5px;
  background: #101011;
  border: 0;
  border-bottom: 2px solid transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  height: 42px;
}
.custom_tabbtn_grp button.active {
  background: #ff493c;
  color: #fff;
}
.add_media_box {
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.02) 0px 12px 24px -4px;
  padding: 20px;
  background: #101011;
  border-radius: 5px;
}
.add_media_box_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.add_media_box_head h4 {
  margin-bottom: 0;
}
.add_media_box_head > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.add_media_box_head span.badge.bg-primary {
  background: #2d425c !important;
  font-size: 14px;
  font-weight: 400;
}
.form-check-input {
  box-shadow: none !important;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.7);
}
.form-check-input:checked {
  background-color: #ff493c;
  border-color: #ff493c;
}
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker-wrapper input {
  width: 100%;
}
.primary_input[type="file"] {
  position: absolute;
  top: 30px;
  opacity: 0;
}
.pos_rel {
  position: relative;
}
.support_accordion {
  margin-top: 30px;
}
.support_accordion .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  background: #1b232d;
  border-color: #1b232d;
}
.support_accordion .accordion-button {
  border-radius: 10px !important;
  background: #1b232d;
  box-shadow: none !important;
}
.support_accordion .accordion-button::after {
  filter: invert(1);
  background-size: 80%;
}
.support_accordion .accordion-button:not(.collapsed)::after {
  filter: unset;
}
.support_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.supportissue_type {
  margin-bottom: 0;
  color: #5d87ff;
  font-weight: 400;
  line-height: normal;
}
.supportissue_type span {
  color: rgba(255, 255, 255, 0.5);
}
.supportissue_details {
  display: flex;
  gap: 24px;
  margin-right: 30px;
}
.chat_wrapper {
  min-height: 400px;
  overflow-x: auto;
  max-height: 400px;
  margin-bottom: 10px;
}
.chat_single {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 12px;
}
.chat_single img {
  border-radius: 50px;
  border: 1px solid #1e232e;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  width: 50px;
  height: 50px;
}
.chat_msg {
  width: 70%;
  background: #101011;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
}
.chat_msg p {
  margin-bottom: 5px;
}
.chat_msg span {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}
.chat_single_admin .chat_msg {
  margin-left: auto;
  background: #282f39;
}
.chat_single.chat_single_admin {
  margin-right: 10px;
}
button.msg_attach input[type="file"] {
  position: absolute;
  opacity: 0;
  width: 64px;
  height: 100%;
}
.support_footer_msg button {
  background: #5d87ff;
}
/* Home page css */
.page_header {
  position: relative;
  z-index: 1;
  padding: 150px 0;
  display: flex;
  align-items: center;
  background: url("../src/assets/images/banner_bg.jpg") no-repeat top center;
  background-size: cover;
  min-height: 720px;
  margin-top: -78px;
}
.page_header::before {
  content: "";
  background: rgb(0 0 0 / 75%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.page_header h1 {
  color: #fff;
  font-size: 56px;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 30px;
}
.page_header p {
  color: #a5b3c8;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 30px;
  z-index: 10;
  position: relative;
}
.main_wrapper {
  min-height: 100vh;
}
.section {
  padding: 50px 0;
}
.how_section {
  background: #1c232d;
}
.inner_title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}
.trusted_slider .slider_box {
  background: #f7faff;
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: center;
  border-radius: 5px;
}
.inner_para {
  color: #a5b3c8;
  font-size: 18px;
  margin-bottom: 30px;
}
.faq_accordian .accordion-item button {
  color: #fff;
  font-size: 20px;
}
.faq_accordian .accordion-item .accordion-body p {
  color: #a5b3c8;
  font-size: 18px;
}
footer.footer {
  background: #1b232d;
  padding: 50px 0 20px;
}
.footer_top {
  padding-bottom: 15px;
}
.footer_bottom p {
  text-align: center;
}
.footer_top h3 {
  font-size: 20px;
  color: #5d87ff;
  margin-bottom: 20px;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
}
.footer_menu_links li {
  margin-bottom: 15px;
}
.footer_menu_links li a {
  color: #fff;
}
.footer_menu_links li a:hover {
  color: #5d87ff;
}
.footer_social_links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.footer_social_links li a {
  background: #2e3d52;
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: 0.2s all;
  color: #fff;
}
.footer_social_links li a:hover {
  background: #0f1319;
}
.footer_bottom {
  border-top: 1px solid #2f2f2f;
  padding-top: 20px;
}
.footer_logo p {
  color: #a5b3c8;
}

/* New Css */
.avatar_content {
  position: absolute;
  inset: 0;
  padding: 0 80px;
  display: flex;
}
.avatar_content_layout {
  padding: 0 150px;
  display: flex;
  flex-direction: row;
  position: absolute;
  inset: 0;
}
.avatar_content_layout .backdrop {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(
    57.91% 100% at 50.04% 50%,
    rgba(0, 0, 0, 0) 20.27%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
.canvas_overlay {
  z-index: 1;
}
.avatar_left_column {
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 634px;
  padding: 100px 0 150px;
  z-index: 1;
  background: rgb(46 46 46 / 60%);
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin: 30px 0;
  border-radius: 10px;
}
.avatar_left_column h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #fff;
  margin: 8px 0 15px;
}
.avatar_left_column_container {
  display: block;
  height: 100%;
  box-sizing: border-box;
}
.scroll_step {
  scroll-snap-align: start;
}
.step_max.canvas_overlay_top {
  z-index: 10;
}
.step_max {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.avatar_left_column_mobile_box {
  width: 100%;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}
.avatar_left_column_mobile_box .line {
  background-color: #7c828a;
  margin: 12px;
  height: 4px;
  width: 76px;
  border-radius: 2px;
}
.avatar_center_column {
  flex-shrink: 1;
  flex-grow: 1;
}
.avatar_right_column {
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 450px;
  padding: 100px 0 150px;
  position: relative;
  gap: 20px;
  z-index: 1;
}
.collection_item {
  flex-shrink: 0;
  width: calc(
    (100% - 16px * (var(--max-item-count) - 1)) / var(--max-item-count)
  );
  scroll-snap-align: start;
  overflow: hidden;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
  overflow: visible;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.collection_item:hover .collection_item_img {
  border: 2px solid #fff;
}
.collection_item.active .collection_item_img {
  border: 2px solid #ff493c;
}
.collection_item_img {
  width: 100%;
  max-width: 70px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  transition: all 0.05s;
  background: #fff;
  position: relative;
  aspect-ratio: 1;
}
.collection_item_img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.collection_item_name {
  width: 100%;
  height: 18px;
  margin: 0 2px;
  font-family: Montserrat, sans-serif;
  color: #bec1c4;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.avatar_name_details p {
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  color: #dee0e2;
  margin-bottom: 5px;
}
.avatar_collection_name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.avatar_collection_name a {
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  color: #ff493c;
}
.avatar_left_column_tabcontent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.collection_filter_set {
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
.collection_filter_set .search_container {
  flex-shrink: 1;
  flex-grow: 1;
}
.search_field {
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  height: 32px;
  background: #141417;
  border-radius: 4px;
}
.search_field input {
  appearance: none;
  outline: none;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9da1a7;
}
.collection_filter_set .button_container {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  gap: 8px;
}
.filter_button {
  position: relative;
  width: 32px;
  height: 32px;
}
.button_icon_square {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #141417;
  border-radius: 7px;
  min-width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid transparent;
}
.button_icon_square:hover,
.button_icon_square.selected {
  border: 1px solid #e5f2ff;
}
.avatar_left_column_top {
  gap: 16px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dropdownmenu_custom {
  padding: 0;
  background-color: #21262d;
  max-height: 280px;
  overflow: auto;
  border-radius: 8px;
}
.AvatarAnim_active_icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.collection_filter_set .dropdownmenu_custom li {
  position: relative;
}
.collection_filter_set .dropdown-item {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  flex-grow: 1;
  white-space: nowrap;
  margin-right: 20px;
}
.collection_panel {
  --gap: 8px;
  --max-column-count: 5;
  --min-column-width: 96px;
  --gap-count: calc(var(--max-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--gap));
  --max-column-width: calc(
    (100% - var(--total-gap-width)) / var(--max-column-count)
  );
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--min-column-width), var(--max-column-width)), 1fr)
  );
  grid-template-rows: 1fr;
  gap: var(--gap);
  margin-right: 12px;
}
.avatar_collection_card {
  overflow: visible;
  min-width: 96px;
  aspect-ratio: 0.8;
  position: relative;
  border: 2px solid #282e36;
  border-radius: 8px;
  background: transparent linear-gradient(180deg, #FCB879 20%, #ED2026 100%) 0 0
    no-repeat padding-box;
  cursor: pointer;
  box-shadow: 0 0 8px #0000004d;
  box-sizing: border-box;
  overflow: hidden;
  user-select: none;
}
.avatar_collection_card:hover {
  border-color: #ccc;
}
.avatar_collection_card.active {
  border-color: #ff493c;
}
.avatar_collection_card_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.avatar_icon_size {
  z-index: 1;
  display: none;
  align-items: center;
  flex-direction: row;
  position: absolute;
  padding-left: 8px;
  padding-top: 4px;
}
.avatar_collection_card:hover .avatar_icon_size {
  display: flex;
}
.avatar_icon_size p {
  font-size: 12px;
  font-weight: 600;
  padding: 4px;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
}
.avatar_price {
  position: absolute;
  top: 6px;
  right: 4px;
}
.avatar_price_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 3px 5px;
  background-color: #212931;
  gap: 4px;
}
.avatar_price_container img {
  width: 12px;
  height: 12px;
}
.avatar_price_container p {
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 0;
}
.collection_avatar_image {
  display: block;
  border-radius: initial;
  width: 100%;
  height: 100%;
}
.collection_avatar_image img {
  object-fit: cover;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
}
.avatar_shadow {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 6px;
  background: linear-gradient(0deg, rgb(0, 0, 0), rgba(255, 255, 255, 0) 20%);
}
.collection_avatar_name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.avatar_overview {
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.avatar_size_details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  height: 36px;
  gap: 12px;
  color: #dee0e2;
  flex-grow: 0;
  flex-shrink: 0;
}
.avatar_size_info p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 5px;
}
.size_details_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.size_details_container img {
  width: 14px;
  height: 14px;
}
.stats_seperator {
  background: #bec1c4;
  width: 2px;
  height: 100%;
}
.traits_details h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: #dee0e2;
  text-transform: uppercase;
}
.traits_details {
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}
.traits_info {
  flex-shrink: 1;
  overflow: hidden;
}
.traits_info p {
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #dee0e2;
  margin-bottom: 5px;
}
.traits_info span {
  font-weight: 700;
}
.avatar_action_btns {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.btn_price_div {
  margin-left: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 600;
}
.btn_price_div img {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}
.avatar_equipped {
  position: absolute;
  top: 0;
  right: 0;
}
.buy_avatar_collection_card {
  min-width: 96px;
  aspect-ratio: 0.8;
  position: relative;
  border: 2px solid #282e36;
  border-radius: 8px;
  background: transparent linear-gradient(180deg, #FCB879 20%, #ED2026 100%) 0 0 no-repeat padding-box;
  cursor: pointer;
  box-shadow: 0 0 8px #0000004d;
  box-sizing: border-box;
  overflow: hidden;
  user-select: none;
}
.buy_avatar_card_background {
  position: absolute;
  inset: 0;
  background: radial-gradient(
      192.23% 132.94% at 49.74% 103.69%,
      rgba(200, 200, 200, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #101011;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.buy_avatar_card_background img {
  flex-shrink: 1;
  flex-grow: 1;
  height: 0;
  align-self: center;
  margin: 13px 0 0;
}
.buy_avatar_card_background p {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 11px 0;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  background: linear-gradient(180deg, rgba(20, 24, 29, 0) 0%, #101011 100%);
  margin-bottom: 0;
}
.buy_avatar_collection_card .circle-plus {
  display: none;
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 32px;
}
.buy_avatar_collection_card:hover {
  border-color: #ccc;
  background: transparent
    linear-gradient(180deg, #091c47 10%, #0d49b2 60%, #12274b 100%) 0 0
    no-repeat padding-box;
}
.buy_avatar_collection_card:hover .circle-plus {
  display: block;
}
.buy_avatar_collection_card:hover .buy_avatar_card_background img {
  opacity: 0.5;
}
.free_avatar_banner {
  position: relative;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: linear-gradient(225deg, #11326f 0%, #ff493c 100%);
  cursor: pointer;
}
.free_avatar_banner img {
  display: block;
  aspect-ratio: 1;
  width: 40%;
  object-fit: cover;
}
.free_avatar_banner p {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 0;
}
button.back_btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #fff;
  font-weight: 400;
  font-size: medium;
  border: none;
  background: transparent !important;
  margin: 0;
  padding: 0;
}
.avatar_customize_options {
  height: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.avatar_customize_options button {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: block;
  background-color: #101011;
  border-radius: 7px;
  cursor: pointer;
  color: #000;
  font-weight: 800;
  border: none;
  box-sizing: border-box;
  padding: 0;
}
.customize_avatar_fullimage img {
  transform-origin: top;
  transform: scale(1.7);
  object-fit: contain;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
}
.avatar_collection_card:hover .customize_avatar_fullimage img {
  padding: 8px 20px 24px;
  transform: unset;
}
.customize_avatar_partsimage img {
  transform-origin: top;
  padding: 8px 20px 24px;
  object-fit: contain;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
}
.colors_panel {
  position: relative;
}
.color_swatch {
  position: absolute;
  right: -20px;
  padding: 10px;
}
.color_option {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  outline: 1px solid #4b5158;
  outline-offset: 3px;
  margin: 14px 0;
  cursor: pointer;
}
.color_option:hover,
.color_option--active {
  outline: 2px solid #ff493c;
}
.color_option_custom {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #4bf52f -46.55deg,
    #2f72f5 19.12deg,
    #950c6f 80.63deg,
    #ff1b5a 142.3deg,
    #c5714f 192.21deg,
    #f5d62f 256.87deg,
    #4bf52f 313.45deg,
    #2f72f5 379.12deg
  );
  position: relative;
}
.color_option_custom input {
  opacity: 0;
  position: absolute;
  top: -5px;
  left: -5px;
}
.color_option_none {
  border: 1px dashed #9da1a7;
  cursor: default;
  outline: 1px solid #9da1a7;
}
.color_option_none::before {
  display: block;
  content: "";
  width: 22px;
  height: 0;
  opacity: 0.5;
  border: 1.5px solid #9da1a7;
  background-color: #9da1a7;
  transform: rotate(-55deg) translate(-32%);
}
.filter_sidebar_panel {
  overflow-y: auto;
  position: fixed;
  width: 320px;
  height: 100%;
  top: 63px;
  right: -320px;
  padding: 0 16px;
  z-index: 13;
  background: #101011;
  border: 1px solid #101011;
  box-shadow: 0 0 24px #0d1015;
  color: #fff;
  transition: right 0.5s ease;
}
.filter_sidebar_panel.show {
  right: 0;
}
.filter_sidebar_panel.hide {
  right: -320px;
}
.filter_sidebar_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 1;
  top: 0;
  margin-right: -16px;
  background: inherit;
  border-bottom: 1px solid #101011;
}
.filter_sidebar_header h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 0;
  color: #7c828a;
}
.filter_sidebar_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.link_button {
  padding: 6px 0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  color: #ff493c;
  transition: color 0.2s;
  margin-bottom: 0;
}
.close_btn {
  height: 62px;
  width: 62px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background: transparent;
  border: none;
}
.filter_options_panel {
  padding-top: 10px;
}
.form_check_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
}
.form_check_flex label {
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  width: 80%;
}
.filter_accordian .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid #101011;
  border-radius: 0;
}
.filter_accordian .accordion-button {
  background: transparent;
  border: none;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: none !important;
}
.filter_accordian .accordion-button::after {
  filter: invert(1);
  width: 16px;
  height: 16px;
  background-size: cover;
}
.filter_accordian .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
.filter_accordian .accordion-body {
  padding: 5px 0 0;
}
.sorting_list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  width: 100%;
}
.sorting_list li {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
  color: #dee0e2;
  font-weight: 400;
  background: #141417;
  position: relative;
}
.sorting_list li:hover {
  background-color: #101011;
}
.sorting_list li img {
  width: 16px;
}
.filter_accordian .accordion-body .form_check_flex {
  background: #141417;
  padding: 10px;
  margin: 10px 0;
}
.filter_options_panel h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 20px 0;
  color: #ff493c;
  margin-bottom: -5px;
  border-bottom: 1px solid #101011;
}
.onsale_option {
  border-bottom: 1px solid #101011;
  padding: 12px 0 15px;
}
.avatar_scene_renderer {
  position: absolute;
  inset: 0;
}
.canvas_wrapper {
  position: absolute;
  inset: 0;
  overflow: hidden;
}
.canvas_wrapper img,
.canvas_wrapper canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  touch-action: none;
  cursor: grab;
  object-fit: cover;
}
.page_map {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 100%;
  height: 100%;
}
.tiled_map_container {
  height: 100%;
  overscroll-behavior-x: none;
  position: relative;
  width: 100%;
}
.map_filter_sidebar {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  left: 0;
  pointer-events: none;
  position: absolute;
  transition: all 0.07s linear;
  width: 100%;
  z-index: 403;
}
.closed {
  left: -255px;
}
.map_filter_sidebar.closed .mapsidebar_close_icon {
  display: none;
}
.map_filter_sidebar.closed .mapsidebar_setting_icon {
  display: block;
}
.map_filter_sidebar .mapsidebar_setting_icon {
  display: none;
}
.no_select {
  user-select: none;
}
.map_sidebar_wrapper {
  -ms-overflow-style: none;
  background-color: #141417;
  height: 100%;
  min-width: 255px;
  overflow-y: auto;
  pointer-events: auto;
  scrollbar-width: none;
}
.mapsidebar_buttons_wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mapsidebar_button {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  pointer-events: auto;
}
.custom_icon_btn {
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: rgba(61, 62, 63, 0.573);
  border: 0;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.502);
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_icon_btn img {
  height: 24px;
  width: 24px;
}
.map_filters_wrapper {
  background-color: #141417;
  padding-top: 20px;
}
.map_filters_wrapper h3 {
  color: #babbbd;
  font-size: 12px;
  line-height: 16px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  margin-bottom: 0;
  text-transform: uppercase;
}
.map_leaflet_container {
  position: relative;
  touch-action: pinch-zoom;
  height: 100%;
  width: 100%;
}
.map_leaflet_container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  cursor: grab;
}
.map_controls.mapsidebar_closed {
  --left-sidebar-offset: 0px;
}
.map_controls {
  bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 320px;
  left: calc(
    20px + var(--leftbar-navigation-offset, 55px) +
      var(--left-sidebar-offset, 255px)
  );
  position: fixed;
  transition: left 0.07s linear;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 401;
}
.map_controls_btn {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
}
.map_filter_btn {
  align-items: center;
  background: none;
  border: none;
  border-radius: 5px;
  color: hsla(0, 0%, 100%, 0.9);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 40px;
  justify-content: flex-start;
  line-height: 20px;
  margin: 7px 6px;
  padding: 8px 10px;
  text-align: left;
  width: 242px;
}
.map_filter_btn img {
  height: 24px;
  margin-right: 16px;
  width: 24px;
}
.map_filter_btn:hover {
  background-color: #262a31;
}
.map_mode_filter {
  margin-top: 16px;
  padding: 0 16px 0 56px;
}
.map_mode_filter .filter_accordian .accordion-body .form_check_flex {
  padding: 0;
  margin: 5px 0;
}
.map_mode_filter .filter_accordian .accordion-button {
  padding: 10px 0;
  font-size: 13px;
  line-height: 16px;
  color: rgb(255 255 255 / 90%);
  font-weight: 500;
}
.map_mode_filter .form_check_flex label {
  font-size: 13px;
  color: rgb(255 255 255 / 70%);
}
.map_mode_filter .filter_accordian .accordion-body {
  padding: 0;
}
.map_layers_section {
  padding: 16px;
}
.map_layers_section h3 {
  color: #babbbd;
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  text-transform: uppercase;
}
.mapmarkers_toggle_sec {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  margin-bottom: 3px;
  width: 100%;
  padding-left: 0;
}
.mapmarkers_toggle_sec img {
  height: 24px;
  width: 24px;
}
.mapmarkers_toggle_sec .form-check-label {
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 16px;
  justify-content: flex-start;
  line-height: 20px;
}
.form_switch_custom .form-check-input {
  background-color: #55575a;
  border-color: #55575a;
  background-repeat: no-repeat;
  box-shadow: none !important;
  height: 18px;
  width: 36px;
}
.form_switch_custom .form-check-input:checked {
  background-color: #0946bf;
  border-color: #0946bf;
}
.map_tags_wrapper {
  color: #fff;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: flex-start;
  line-height: 20px;
  margin: 11px 10px;
  text-align: left;
  width: calc(100% - var(--tags-width));
}
.map_tags_wrapper .map_tag {
  background-color: #21262d;
  border-radius: 18px;
  justify-content: center;
  margin: 4px 2px;
  min-height: 36px;
  opacity: 0.95;
  padding: 8px 16px;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.map_tags_wrapper,
.map_tags_wrapper .map_tag {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.map_tags_wrapper .map_tag img {
  height: 16px;
  margin-left: 7px;
  opacity: 0.7;
  width: 16px;
  cursor: pointer;
}
.map_experiences_list {
  background-image: url("../src/assets/images/map_black_bg.svg");
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  left: auto;
  padding-right: 12px;
  position: absolute;
  right: 0;
  top: auto;
  transition-duration: 0.25s;
  transition-property: all;
  transition-timing-function: ease;
  z-index: 401;
}
.map_experiences_list.hide {
  transform: translate(462px);
}
.map_experiences_list .show_experiences_button {
  height: -moz-fit-content;
  height: fit-content;
  margin-right: 12px;
  margin-top: 12px;
  padding: 9px;
  transition-duration: 0.25s;
  transition-property: all;
  transition-timing-function: ease;
  width: -moz-fit-content;
  width: fit-content;
}
.map_experiences_list .show_experiences_button img.ex_arrow_icon {
  rotate: 180deg;
}
.map_experiences_list .show_experiences_button img.ex_search_icon {
  display: none;
}
.map_experiences_list.hide .show_experiences_button img.ex_search_icon {
  display: block;
}
.map_experiences_list.hide .show_experiences_button img.ex_arrow_icon {
  display: none;
}
.map_experiences_search {
  box-shadow: 0 0 12px 0 rgba(13, 16, 21, 0.5);
  isolation: isolate;
  margin-top: 12px;
  pointer-events: auto;
  width: 450px;
}
.map_experiences_search .search_field {
  background-color: #141417;
  border: 0;
  border-radius: 4px;
  margin-top: 0;
  height: 40px !important;
}
.experiences_search_button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding-left: 12px;
  padding-right: 8px;
}
.experiences_search_button img {
  height: 20px;
  width: 20px;
}
.map_experiences_search .search_field .search_input {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  text-align: left;
  width: 100%;
}
.map_experience_list_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);
  justify-content: flex-start;
  overflow-y: scroll;
  padding-bottom: 10px;
  padding-top: 10px;
  scrollbar-width: none;
  transition: all 0.07s linear;
  width: 450px;
}
.game_jam {
  background-color: #101011;
  border: 1px solid #2e354c;
  border-radius: 10px;
  margin-bottom: 14px;
  position: relative;
  width: 100%;
}
.game_jam img {
  border-radius: 8px 8px 0 0;
  height: 158px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
.game_jam .end_date {
  background-color: #141810;
  border-radius: 5px;
  bottom: auto;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  left: 20px;
  line-height: 16px;
  padding: 4px 10px;
  position: absolute;
  right: auto;
  text-align: center;
  top: 15px;
  z-index: 1;
}
.game_jam_description {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 20px 20px;
}
.game_jam_description .tags {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.game_jam_description .tags .tag {
  border: 1px solid #bec1c4;
  border-radius: 2px;
  color: #bec1c4;
  font-size: 9px;
  line-height: 11px;
  margin-right: 10px;
  padding: 4px 8px;
  text-align: center;
}
.game_jam_description h2 {
  color: #dee0e2;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 6px;
  margin-top: 14px;
  text-align: left;
}
.game_jam_description p {
  color: #dee0e2;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 14px;
  text-align: left;
}
.custom_button.xsmall,
.custom_button.xxsmall {
  align-items: center;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  justify-content: center;
  line-height: 19px;
  max-height: 30px;
  max-width: 130px;
  min-height: 30px;
  min-width: 130px;
  padding: initial;
  text-align: center;
}
.custom_button {
  align-items: center;
  border: none;
  border-radius: 25px;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  height: 50px;
  justify-content: center;
  line-height: 24px;
  min-width: 250px;
  padding: 11px 24px;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  background-color: #ff493c;
}
.game_jam_description .custom_button {
  font-size: 12px;
  max-width: 120px;
  min-width: 120px;
}
.experience_div {
  grid-gap: 16px;
  align-items: center;
  background-color: #101011;
  border: 1px solid #2d323a;
  border-radius: 8px;
  display: grid;
  flex-shrink: 0;
  gap: 16px;
  grid-template-columns: minmax(0, 1fr) 198px;
  min-height: 200px;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 450px;
  margin-bottom: 8px;
}
.experience_div_info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
.experience_div_info .tags,
.map_experience_detail_info_header .tags {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.experience_div_info .tags .tag,
.map_experience_detail_info_header .tags .tag {
  border: 1px solid;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  line-height: 14px;
  margin-right: 6px;
  padding: 4px 8px;
  text-align: left;
}
.experience_div_info .tags .tag.ugc,
.map_experience_detail_info_header .tags .tag.ugc {
  border-color: #ff63c8;
  color: #ff63c8;
}
.experience_div_info .tags .tag.premium {
  border-color: #ffbf36;
  color: #ffbf36;
}
.experience_div_info .experience_name {
  margin-bottom: 0;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: #dee0e2;
  display: -webkit-box;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-word;
}
.experience_div_info .experience_user_tag {
  color: #ff493c;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 2px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}
.experience_stats {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}
.experience_stats img {
  width: auto;
  height: 16px;
  margin-right: 5px;
}
.experience_stats p {
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  margin-right: 10px;
  text-align: left;
  white-space: nowrap;
  margin-bottom: 0;
}
.map_experience_cta {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  margin-top: auto;
}
.custom_icon_btn.xs_btn {
  min-height: 28px;
  min-width: 28px;
}
.custom_icon_btn.xs_btn img {
  height: 16px;
  width: 16px;
}
.event_notification {
  align-items: center;
  background: #6600e8;
  border-radius: 50%;
  bottom: 1px;
  color: #fff;
  display: flex;
  font-weight: 500;
  font-size: 10px;
  height: 15px;
  justify-content: center;
  line-height: 10px;
  position: absolute;
  right: -5px;
  text-align: left;
  width: 15px;
  z-index: 300;
}
.experience_preview_cta {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.experience_preview_cta img {
  aspect-ratio: 1 / 1;
  height: auto;
  max-width: none;
  min-height: 100%;  
  object-fit: cover;
  width: 100%;
}
.experience_div:hover .experience_preview_cta img {
  transform: scale(1.1);
  transition: transform .2s ease-in-out;
}
.experience_premium_div {
  gap: 0;
  grid-template-columns: none;
}
.experience_premium_div .experience_preview_cta {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 150px;
  overflow: hidden;
}
.map_experience_detail_wrapper {
  background-color: #101011;
  border: 1px solid #383e46;
  border-radius: 4px;
  bottom: 12px;
  height: calc(100% - 65px - 24px);
  left: auto;
  overflow: hidden;
  position: fixed;
  right: 12px;
  top: auto;
  width: 450px;
  z-index: 1000;
}
.map_experience_detail_wrapper .custom_icon_btn.close_btn {
  background-color: #101011;
  border: none;
  border-radius: 4px;
  bottom: auto;
  cursor: pointer;
  height: 34px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 34px;
  z-index: 3;
}
.map_experience_detail_body {
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 125px;
  scrollbar-width: none;
}
.map_experience_detail_banner {
  height: 252px;
  overflow: hidden;
  width: 100%;
}
.map_experience_detail_banner img {
  height: 100%;  
  object-fit: cover;
}
.map_experience_detail_info {
  padding: 20px;
  width: 100%;
}
.map_experience_detail_info_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.map_exp_coordinates img {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}
.map_exp_coordinates p {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
.custom_icon_btn.sm_btn {
  min-height: 36px;
  min-width: 36px;
}
.custom_icon_btn.sm_btn img {
  height: 20px;
  width: 20px;
}
.map_experience_detail_info .buttons_container {
  display: flex;
  gap: 8px;
  margin-left: auto;
}
.map_experience_detail_info .buttons_container .custom_icon_btn {
  box-shadow: none;
}
.map_experience_detail_info .buttons_container .custom_icon_btn:hover {
  background-color: #373b3f;
}
.map_experience_detail_info_resume {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin-top: 12px;
}
.map_experience_detail_info_resume h3 {
  font-size: 22px;
  line-height: 28px;
  text-align: left;
  font-weight: 700;
}
.experience_desc {
  font-size: 14px;
  line-height: 20px;
}
.map_experience_detail_creator {
  margin-top: 28px;
}
.owner_wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.owner_info p {
  font-size: 12px;
  color: #bdc0c2;
  line-height: 20px;
  margin-bottom: 0;
}
.owner_info a {
  color: #ff493c;  
  font-size: 12px;
  line-height: 15px;
}
.owner_socials {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-around;
}
.owner_socials img {
  opacity: 0.9;
  width: 28px;
}
.map_experience_rating {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
  width: 100%;
}
.map_experience_rating p {
  font-size: 12px;
  letter-spacing: 1.2px;
  line-height: 23px;
  opacity: .7;
  text-transform: uppercase;
  margin-bottom: 0;
}
.star_rating_star {
  color: #ababab;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  padding: 3px;
  transition: color .2s ease-out;
  vertical-align: middle;
}
.star_rating_checkbox {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.experience_details_footer_wrapper {
  background-color: #101011;
  border-top: 1px solid #383e46;
  bottom: 0;
  left: auto;
  padding: 16px 20px;
  position: absolute;
  right: auto;
  top: auto;
  width: 100%;
  z-index: 1;
}
.experience_details_footer_cta {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}
.mobile_buttons_container {
  display: none;
  right: 0;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: flex-end;
}
.canvas_overlay_top {
  z-index: 3;
}
.mobile_assets_overview {
  overflow-y: auto;
  position: absolute;
  width: 350px;
  height: 100%;
  top: 0;
  right: -350px;
  z-index: 13;
  background: #101011;
  border: 1px solid #101011;
  box-shadow: 0 0 24px #0d1015;
  color: #dee0e2;
  transition: right 0.5s ease;
}
.mobile_assets_overview.show {
  right: 0;
}
.mobile_assets_overview_header {
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #7c828a;
  padding: 0;
}
.mobile_assets_overview_content {
  padding: 0 40px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
}
.avatar_right_column .header {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.mar_ket .market_nft{
  padding: 50px 80px;
  width: unset;
  margin: 0;
}
.mar_ket .ban{
  /* background: url("./assets/images/market_ban.avif") no-repeat center; */
  background: transparent linear-gradient(180deg, #FCB879 20%, #ED2026 100%) 0 0 no-repeat padding-box;
  background-size: cover;
}
.mar_ket .ban{
  padding:100px 80px;
}
.mar_ket .ban h2{
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
.mar_ket .ban p{
  line-height: 24px;
  margin-top: 4px;
  font-weight: 500;
}
.market_nft h1{
  font-size: 35px;
  margin-bottom: 15px;
  font-weight: 700;
}
.fil_flx {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.fil_flx > div {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #21262d;
  color: #bec1c4;
  font-size: 14px;
  padding: 8px 16px;
  font-weight: 700;
  cursor: pointer;
}
.fil_flx > div.active {
  background: #00adef;
  color: #fff;
}




.transform h5{
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}
.transform h5 span{
  font-size: 12px;
  color: #bdc0c2;
}
.transform p{
  font-size: 14px;
  line-height: 24px;
}



.nft_box{
  background: #141417;
  border: 1px solid #141417;
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
  display: block;
  color: #fff !important;
  margin-bottom: 24px;
  width: 100%;
}
.nft_box .img_box{
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.nft_box .img_box img{
  max-height: 250px;
}

.nft_box .img_box .logo_round{
  background-color: #1e232a;
  width: 32px;
  height: 32px;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 7px;
  border-radius: 50%;
  transition: .5s;
}
.nft_box a{
  margin-bottom: 20px;
  display: block;
  color: #fff !important;
}
.nft_box .drp_dwn .dropdown-item{
  margin-bottom: 0;
}
.nft_box a,
.nft_box h6{
  font-weight: 600;
 }
 .nft_box h6{
  margin-bottom: 0;
 }
 .nft_box p{
  color: #9da1a7;
  font-size: 12px;
  margin-bottom: 4px;
 }
.nft_box .flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
 }
 .drp_dwn{
  opacity: 0;
  transition: .5s;
  display: flex; 
 }
.drp_dwn .dropdown-toggle{
  background-color: #1e232a !important;
  border: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.drp_dwn .dropdown-toggle::after{
  display: none;
}
.drp_dwn .dropdown-toggle img{
  width: 25px;
}
.drp_dwn .dropdown-menu{
  background: #282e38;
  padding: 4px;
}
.drp_dwn .dropdown-item{
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px !important;
}
.drp_dwn .dropdown-item:hover{
  background-color: #e9ecef12;
}
.nft_box:hover .drp_dwn{
  transition: .5s;
  opacity: 1;
}
.nft_box:hover .img_box .logo_round{
  transition: .5s;
  opacity: 1;
}
.nft_box .img_box .bg{
  background: radial-gradient(50.18% 50% at 50% 50.18%, hsla(0, 1%, 62%, .5) 0, rgba(6, 30, 92, 0) 100%),
   linear-gradient(to bottom right, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 100% 100% / 50% 50% no-repeat,
    linear-gradient(to bottom left, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 0 100% / 50% 50% no-repeat,
     linear-gradient(to top left, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 0 0 / 50% 50% no-repeat,
      linear-gradient(to top right, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 100% 0 / 50% 50% no-repeat,
       linear-gradient(to bottom right, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 100% 100% / 50% 50% no-repeat,
        linear-gradient(to bottom left, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 0 100% / 50% 50% no-repeat,
        linear-gradient(to top left, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 0 0 / 50% 50% no-repeat, linear-gradient(to top right, hsla(0, 1%, 62%, .2), rgba(6, 30, 92, 0) 50%) 100% 0 / 50% 50% no-repeat, radial-gradient(75.14% 100% at 50.18% 0, hsla(0, 5%, 84%, .25) 0, rgba(6, 30, 92, 0) 100%);

        left: 0;
        right: 0;
        z-index: -1;
        filter: blur(35px);
        height: 100%;
        position: absolute;
        width: 100%;
}

.market_nft{
  width: 1080px;
  margin: 30px auto;
}
.market_nft_detail {
  display: flex;
  gap: 24px;
  margin-bottom: 40px;
}
.market_nft_detail .left_side{
  width: 660px;
  min-width: 660px;
}
.back_butn{
  position: relative;
  background: #24242a;
  display: inline-block;
  border-radius: 120px;
  padding: 6px 15px 6px 23px;
  margin-bottom: 24px;
 cursor: pointer;
 color: #fff !important;
 font-size: 15px;
}
.back_butn::before{
  content: "";
  position: absolute;
  border: solid #9b9ea2;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  left: 11px;
  bottom: 40%;
}


.custom_accordion .accordion-item {
  background: transparent;
  border: 0;
  margin-bottom: 24px;
}
.custom_accordion .accordion-header{
  margin-bottom: 2px !important;
}
.custom_accordion .accordion-button{
  background: #141417;
  border-radius: 4px;
  border: 0;
  box-shadow: none;
  color: #fff;
  font-weight: 500;
}
.custom_accordion .accordion-button::after{
  filter: invert(0) brightness(101);
}
.custom_accordion .accordion-body {
  background: #1a1a1c;
  border-radius: 4px;
  color: #fff;
  padding: 24px;
}
.custom_accordion .nodata{
  color: #bec1c4;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 20px;
}
.custom_accordion .note{
  font-size: 12px;
}
.custom_accordion .note a{
color: #ff493c !important;
}
.custom_accordion .title{
  color: #7c828a;
  font-size: 12px;
  margin-bottom: 10px;
}
.custom_accordion .para{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}
.custom_accordion .table_box{
  display: flex;
  font-size: 12px;
  gap: 16px;
  margin-bottom: 15px;
}
.custom_accordion .table_box>div{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
}
.custom_accordion .table_box .icons{
  height: 12px;
}
.grey{
  color: #bec1c4;
}
.custom_table table{
  border-color: #393d42;
}
.custom_table th{
  font-size: 12px;
  color: #7c828a;
  font-weight: 400;
  text-align: center;
  padding: 8px 24px;
}
.custom_table td{
  font-size: 12px;
  color: #bec1c4;
  text-align: center;
  vertical-align: middle;
}
.custom_table td p{
 margin-bottom: 0;
 font-size: 14px;
 font-weight: 600;
 color: #fff;
}
.custom_table .wid{
  width: fit-content;
  margin: 0 auto;
  text-align: right
}
 .blue{
  color: #ff493c !important;
}
.buy_btn{
  border: 1px solid #dee0e2;
  border-radius: 100px;
  color: #dee0e2;
  padding: 6px 20px;
  font-weight: 600;
  cursor: pointer;
}
.market_nft_detail .right_side{
  flex:1;
  position: sticky;
  top: 90px;
}
.market_nft_detail .icons_flx{
  display: flex;
  gap: 5px;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
}
.market_nft_detail .icons_flx .circle{
  background-color: #1e232a !important;
  border: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  height:32px;
  width:32px;
  border-radius: 50%;
}
.market_nft_detail .icons_flx .drp_dwn .dropdown-toggle{
  height:32px;
  width:32px;
}
.market_nft_detail .right_side .fl_x{
  display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 30px;
}
.market_nft_detail .right_side .fl_x > div{
  align-items: center;
  display: flex;
  gap: 10px;
}
.market_nft_detail .right_side .fl_x img{
  height: 24px;
}
.market_nft_detail .right_side h5{
  color: #fff;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.market_nft_detail .right_side .prf{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 15px;
}
.market_nft_detail .right_side .prf img{
 border-radius: 50%;
 width: 40px;
 height: auto;
}
.market_nft_detail .right_side .prf p{
  margin-bottom: 0;
}
.market_nft_detail .right_side .sm_txt{
  color: #7c828a;
  font-size: 12px;
} 
.market_nft_detail .buy_box{
  border-radius: 12px;
  background: #141417;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  padding: 16px;
}
.market_nft_detail .buy_box .h_tit{
  color: #676c74;
  font-size: 10px;
}
.market_nft_detail .buy_box .h_tit .polygon{
  filter: invert(43%) sepia(16%) saturate(217%) hue-rotate(178deg) brightness(92%) contrast(86%);
  height: 16px;
  width: 16px;
  margin-right: 5px;
}
.market_nft_detail .buy_box .value{
  display: flex;
  gap: 10px;
  align-items: center;
}
.market_nft_detail .buy_box .value h6{
  color: #fff;
  font-size: 24px;
  line-height: 32px;
  margin-left: 4px;
  margin-bottom: 0;
  font-weight: 700;
}
.market_nft_detail .buy_box .value h6 span{
  color: #9da1a7;
  font-size: 14px;
}
.market_nft_detail .buy_box .buy_line{
  display: flex;
  align-items: center;
  gap: 20px;
}
.market_nft_detail .buy_box .buy_line button{
  border: 0 !important ;
  color: #fff;
  font-weight: 600;
  background-color: #ff493c;
  align-items: center;
  border-radius: 20px;
  width: 100%;
  font-size: 16px;
  padding: 7px;
}
.market_nft_detail .buy_box .buy_line .circle {
  background-color: #2f3740;
  border: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
}
.market_nft_detail .buy_box .ylw_t{
  font-size: 12px;
  color: #ffd73d;
}
.cursor-pointer{
  cursor: pointer;
}

.market_nft .h_flx{
 display: flex;
 align-items: center;
 justify-content: space-between;
 color: #676c74;
 font-size: 14px;
 margin-bottom: 15px;
 font-weight: 600;
 flex-wrap: wrap;
}
.market_nft .h_flx p{
  margin-bottom: 0;
}
.market_nft .h_flx>a{
  display: flex;
  align-items: center;
}
.market_nft .h_flx .circle{
  align-items: center;
  background: #21262d;
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 4px;
  width: 24px;
  margin-left: 5px;
}
.break-word{
  word-break: break-word;
}
.custom_modal .btn-close{
  filter: invert(1);
  outline: 0;
  box-shadow: none;
}
.custom_modal .modal-title{
  font-size: 18px;
  line-height: 24px;
  margin: 4px 0;
  padding: 4px 0;
  text-align: left;
}
.custom_modal .modal-header{
  border-bottom: 2px solid #505861;
  padding-left: 0;
  padding-right: 0;
}
.custom_modal .modal-content{
  background-color: #282e36;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}
.custom_modal .modal-body{
  padding-left: 0;
  padding-right: 0;
}
.custom_modal .text{
  font-size: 11px;
  line-height: 13px;
  color: #bdc0c2;
}
.custom_modal .cancel{
  background: rgba(0, 0, 0, .2) !important;
  border: 2px solid #bdc0c2;
  color: #fff;
  font-size: 14px;
  border-radius: 120px;
  padding:8px 30px;
  font-weight: 600;
}
.custom_modal .continue{
  background-color: #ff493c;
  border: 2px solid #ff493c;
  color: #fff;
  font-size: 14px;
  border-radius: 120px;
  padding:8px 30px;
  font-weight: 600;
}
.custom_chk {
  background-color: #353c43;
  display: flex;
  flex-flow: row;
  gap: 2px;
  margin-bottom: 3px;
  padding: 6px 10px;
  text-align: left;
  position: relative;
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.custom_chk .form-check {
  width: auto;
  padding: 0;
  margin: 0;
}
.custom_chk .form-check-input {
  width: 0.7em;
  height: 0.7em;
}
.custom_chk label{
  width: 160px;
  font-size: 15px;
}
.custom_chk .form-check-input[type=radio]{
  margin-left: 0;
  margin-top: 6px;
  margin-right: 4px;
}

.custom_tab .nav{
  gap: 5px;
}
.custom_tab .nav-link{
  background-color: #28282c; 
  border-radius: 5px;
  color: #fff !important;
  outline: 0;
}
.custom_tab .nav-link.active{
background: #18181c;
opacity: 1;
}


.p_list {
  display: flex;
  align-items: center;
}

.p_list_pp {
  display: inline-block;
  width: 54px;
  height: auto;
  margin-left: 0px;
  margin-top: 0px;
  z-index: 10;
  min-height: 40px;
}
.p_list_pp img {
  width: 100%;
  border-radius: 100% !important;
}

.p_list_pp i {
  color: #ffffff;
  font-size: 10px;
  padding: 3px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}


.p_list_info {
  font-weight: 400;
  padding-left: 15px;
}

.dark-scheme .p_list_info b {
  color: #ffffff;
}

.p_list_info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2em;
  word-break: break-word;
}

.p_list_info a {
  color: #1a1b1e;
}

.text-light .p_list_info a {
  color: #ffffff;
}
.butn{
  background-color: #ff493c;
  border: 2px solid #ff493c;
  color: #fff;
  font-size: 14px;
  border-radius: 120px;
  padding: 8px 30px;
  font-weight: 600;
}
.cancel_btn{
background: rgba(0, 0, 0, .2) !important;
  border: 2px solid #bdc0c2;
  color: #fff;
  font-size: 14px;
  border-radius: 120px;
  padding:8px 30px;
  font-weight: 600;
}
.bdr{
  border-bottom: 1px solid #505861;
  border-top: 1px solid #505861;
}
.input_box input{
  background-color: #181c22 !important;
  border: 0;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 8px;
}
.input_box input::placeholder{
  color: #bec1c4;
  font-size: 12px;
}
.input_box input:-webkit-autofill {
  -webkit-text-fill-color: #ffffff;
  background: rgba(255, 0, 0, 0) !important;
  transition: background-color 5000s ease-in-out 0s;
}



/* Media Queries */

@media screen and (max-width: 2559px) {
  .avatar_left_column {
    width: 510px;
    padding: 15px 0 75px;
  }
  .avatar_right_column {
    width: 332px;
    padding: 40px 0 75px;
  }
  .avatar_content_layout {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1919px) {
  .avatar_left_column {
    width: 420px;
  }
  .avatar_right_column {
    width: 288px;
    padding: 40px 0 75px;
  }
}
@media screen and (max-width: 1439px) {
  .avatar_left_column {
    width: 320px;
  }
  .avatar_right_column {
    width: 248px;
  }
}
@media screen and (max-width: 1399px) {
  .total_fund_value h3 {
    font-size: 24px;
  }
  .total_fund_value {
    gap: 24px;
  }
}
@media screen and (max-width: 1279px) {
  .hrs_chart_flex {
    flex-direction: column;
  }
  .total_fund_value h3 {
    font-size: 18px;
  }
  .wallet_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .map_controls {
    --leftbar-navigation-offset: 0px;
  }
}
@media screen and (max-width: 1199px) {
  .market_nft{
    width: 95%;
  }
  .topnavbar_right i.fa-bars {
    display: block;
    font-size: 20px;
  }
  .app_sidebar {
    /* left: -250px;
    transition: left 0.3s ease; */
    display: none;
  }
  .page_wrapper {
    margin-left: 0;
  }
  .app_header {
    left: 0;
  }
  .app_sidebar.show {
    left: 0;
  }
  body.menu_overlay {
    overflow: hidden;
    position: relative;
  }
  body.menu_overlay::before {
    content: "";
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
  }
  .app_sidebar i.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    display: block;
  }
  .offcanvas {
    background: #101011;
  }
  .auth_left {
    width: 70%;
  }
  .app_header .offcanvas-body .navbar-nav {
    align-items: flex-start;
  }
  .offcanvas-body .navbar-nav li a {
    color: #fff;
  }
  .app_header .offcanvas-body,
  .app_header .offcanvas-body .navbar-nav {
    gap: 8px;
  }
  .avatar_left_column_container {
    overflow: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
  }
  .step_min {
    box-sizing: border-box;
    height: calc(100% - 340px);
  }
  .step_max {
    padding: 0 20px 20px;
    background-color: #101011;
    box-sizing: border-box;
  }
  .avatar_left_column_mobile_box {
    display: flex;
  }
  .avatar_left_column h3 {
    display: none;
  }
  .avatar_left_column {
    position: absolute;
    inset: 0;
    padding: 0;
    overflow: hidden;
    width: unset;
    z-index: unset;
  }
  .collection_slider .slick-prev {
    left: 0;
    z-index: 1;
  }
  .collection_slider .slick-next {
    right: 0;
    z-index: 1;
  }
  .collection_panel {
    --max-column-count: 8;
  }
  .avatar_right_column {
    position: absolute;
    inset: 0;
    padding: 28px 28px 380px;
    width: auto;
  }
  .collection_filter_set {
    position: absolute;
    left: 0;
    right: 0;
    top: -16px;
  }
  .search_container {
    display: none;
  }
  .collection_filter_set .button_container {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
  }
  .mobile_buttons_container {
    position: absolute;
    right: 28px;
    flex-direction: column;
    display: flex;
  }
  .avatar_right_column .avatar_overview .traits_details {
    display: none;
  }
  .avatar_content_layout {
    padding: 0;
    flex-direction: column-reverse;
  }
  .avatar_content_layout .backdrop {
    display: none;
  }
  .collection_filter_set .display_anim_btn {
    display: none;
  }
  .free_avatar_banner {
    display: none;
  }
  .avatar_right_column .header {
    display: flex;
  }
  .avatar_customize_options button {
    width: 32px;
    height: 32px;
  }
  .avatar_customize_options button svg {
    width: 18px;
  }
}
@media screen and (max-width: 991px) {
  .market_nft_detail .left_side{
    width: auto;
    min-width: unset;
  }
  .market_nft_detail{
    flex-direction: column;
  }
  .top_filter_wrapper {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .top_filter {
    flex-wrap: wrap;
  }
  .auth_wrapper::before {
    display: none;
  }
  .auth_left {
    width: 100%;
  }
  .sendquote_div {
    flex-direction: column;
  }
  .sendquote_div_left,
  .sendquote_div_right {
    width: 100%;
  }
  .how_section {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .mar_ket .ban {
    padding: 100px 13px;
}
.market_nft {
  padding: 50px 13px;
}
  .auth_left h2 {
    font-size: 32px;
  }
  .total_fund_value {
    flex-wrap: wrap;
  }
  .chat_msg {
    width: 100%;
  }
  .chat_single {
    margin-right: 10px;
  }
  .support_head {
    flex-direction: column;
    gap: 10px;
  }
  .trusted_slider .slider_box {
    margin: 30px;
  }
  .trusted_slider .slick-next {
    right: 0;
  }
  .trusted_slider .slick-prev {
    left: 0;
  }
}
@media screen and (max-width: 575px) {
  .dashtitle_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .usericon_dropdown span {
    display: none;
  }
  .page_title h2 {
    font-size: 24px;
  }
  .topnavbar_right {
    gap: 15px;
  }
  .topnavbar_right .dropdown-toggle::after {
    margin-left: 10px;
  }
  .page_content .container-fluid,
  .app_header .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .top_filter .MuiFormControl-root,
  .top_filter_wrapper .MuiButton-root {
    width: 100%;
  }
  .auth_left h2 {
    font-size: 24px;
  }
  .auth_footer {
    flex-direction: column;
    align-items: unset;
  }
  .auth_left {
    padding: 30px 20px;
  }
  .auth_footer a {
    text-align: center;
    margin-top: 30px;
  }
  .otp_wrap input.form-control {
    padding: 8px 5px;
  }
  .link_box_panel {
    gap: 12px;
  }
  .traffic_chart_flex {
    flex-direction: column;
  }
  .asset_chart_info {
    width: 100%;
  }
  .wallet_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .supportissue_details {
    flex-direction: column;
    gap: 10px;
  }
  .chat_single {
    flex-direction: column;
  }
  .chat_single.chat_single_admin {
    flex-direction: column-reverse;
  }
}



.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}


ul.p-0.list-unstyled {
  overflow-y: scroll;
  height: 265px;
}